<template>
  <h1>Mes commandes</h1>
  <div class="row">
    <div class="col-lg-12">
        <div class="ibox">
            <div class="ibox-content" v-if="commandes.length > 0">
                <div class="table-responsive">
                    <table class="table table-striped table-bordered table-hover dataTables-example" >
                        <thead>
                            <tr>
                                <th>#ID</th>
                                <th>Date Création</th>
                                <th>Client</th>
                                <th>Expérience</th>
                                <th>Prix HT</th>
                                <th>Prix TTC</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="gradeX" v-for="commande in commandes" :key="commande.id">
                                <td>{{ commande.reference }}</td>
                                <td>{{  $filters.formatDate(commande.created_at) }}</td>
                                <td>{{ commande.client.prenom }} {{ commande.client.nom }}</td>
                                <td>
                                  <div v-if="commande.offre">
                                    {{ commande.offre.titre }}
                                  </div>
                                </td>
                                <td>{{ commande.prix_total }} €</td>
                                <td>{{ commande.prix_total_ttc }} €</td>
                                <td>
                                  <router-link  :to="{ name: 'commande', params: { uuid: commande.uuid }}"><i class="fa fa-edit text-navy"></i></router-link>
                                </td>                                                                
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: 'Commandes',
  data() {
    return {  
    }
  },
  created () {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData()
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData'
  },
  methods: {
    fetchData () {      
      this.$store.dispatch('getCommandes');
    }
  },
  computed: {
    commandes () {
      return this.$store.state.commandes.commandes
    },
  },
  components: {
  },
};
</script>